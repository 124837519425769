<template>
  <div class="admin">
    <el-container
        style="min-height: 100vh; "
        :class="{'bg-img' : activeNav === '1-1' || activeNav === '1-2' || activeNav === '1-3' || activeNav === '2-1' || activeNav === '2-2'|| activeNav === '2-3'|| activeNav === '2-6' || activeNav === '2-9' || activeNav === '2-10' || activeNav === '2-11' || activeNav === '7-12' || activeNav === '7-13' || activeNav === '7-18'||activeNav === '2-13'}"
    >
      <el-aside style=" width: initial; background-color: #1A366A" class="aside" v-show="!fullScreen">
        <el-scrollbar style="height: 100%">
          <p class="demonstration f16 mb20 col-1A3 bgc-1A3"></p>
          <el-menu
              v-if="isMenu === 2"
              :default-openeds="defaultArr"
              :default-active="activeNav"
              text-color="#fff"
              :unique-opened="true"
              active-text-color="#409eff"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              :collapse="isCollapse"
              background-color="#1A366A"

          >
            <el-submenu
                v-for="(item, index) in menu_list"
                :key="index"
                :index="item.page_id"
                class="tl f15"
            >
              <template slot="title">
                <i
                    :class="'iconfont ' + item.menu_logo"
                    style="margin: 0 8px"
                ></i>
                <span>{{ item.menu_name }}</span>
              </template>
              <el-menu-item-group>
                <div v-for="(item1, index) in item.menu_list" :key="index">
                  <router-link :to="item1.menu_url">
                    <el-menu-item :index="item1.page_id" class="tc">
                      {{ item1.menu_name }}&nbsp;&nbsp;&nbsp;
                    </el-menu-item>
                  </router-link>
                </div>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
          <el-menu
              v-else-if="isMenu === 1"
              :default-openeds="defaultArr"
              :default-active="activeMenu"
              text-color="#fff"
              :unique-opened="true"
              @open="handleOpen"
              active-text-color="#409eff"
              class="el-menu-vertical-demo"
              :collapse="isCollapse"
              background-color="#1A366A"
          >
            <!-- <el-submenu index="1" class="tl f15"> -->
            <el-submenu index="1" class="tl f15">
              <template slot="title">
                <i class="iconfont iconjibenxinxi mr5"></i>
                <span>基本信息</span>
              </template>
              <el-menu-item-group>
                <router-link to="/admin/public">
                  <el-menu-item index="/admin/public" class="tc">
                    基本信息
                  </el-menu-item>
                </router-link>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2" class="tl f15">
              <template slot="title">
                <i class="iconfont iconmima mr5"></i>
                <span>密码更新</span>
              </template>
              <el-menu-item-group>
                <router-link to="/admin/changePassword">
                  <el-menu-item index="/admin/changePassword" class="tc">
                    密码更新
                  </el-menu-item>
                </router-link>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3" class="tl f15">
              <template slot="title">
                <i class="iconfont iconzhanghao mr5"></i>
                <span>账号更新</span>
              </template>
              <el-menu-item-group>
                <router-link to="/admin/accountUpdate">
                  <el-menu-item index="/admin/accountUpdate" class="tc">
                    账号更新
                  </el-menu-item>
                </router-link>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="user.user_type == 3" index="4" class="tl f15">
              <template slot="title">
                <i class="iconfont iconquanxianguanli mr5"></i>
                <span>主体管理</span>
              </template>
              <el-menu-item-group>
                <router-link to="/admin/companyAdd">
                  <el-menu-item index="/admin/companyAdd" class="tc">
                    新增主体
                  </el-menu-item>
                </router-link>
                <router-link to="/admin/companyList">
                  <el-menu-item index="/admin/companyList" class="tc">
                    企业列表
                  </el-menu-item>
                </router-link>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-container class="w pr">
        <el-header v-show="!fullScreen">
          <p
              class="disib mr15"
              style="float: left; line-height: 60px"
              @click="Collapse"
          >
            <i class="iconfont iconshouqi c000 mr5"></i>
          </p>

          <el-dropdown
              v-if="
              company_name &&
              isMenu == 2 &&
              (user.user_type == 3 || user.user_type == 4)
            "
              class="ml20"
              :hide-on-click="false"
              @command="changeCompany"
          >
            <span class="f14">
              <img style="padding: 1px" src="../assets/image/shouye.png" />
              {{ company_name }}
            </span>
            <el-dropdown-menu
                slot="dropdown"
                style="height: 200px; overflow-y: scroll"
            >
              <el-dropdown-item
                  v-for="(item, index) in companyData"
                  :key="index"
                  :command="item"
              >{{ item.company_name }}
              </el-dropdown-item>
              <!-- <el-dropdown-item v-if="user.user_type==3" command="company">企业切换</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
              class="ml20 mr20"
              :hide-on-click="false"
              @command="LoginOut"
          >
            <span class="el-dropdown-link">
              <img class src="../assets/image/my.png" />
              {{ user.user_name }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
              v-if="isMenu === 2 && user.user_type == 3 && company_name"
              class="back_btn"
              size="medium"
              plain
              @click="Back(company_id)"
          >返回个人中心</el-button
          >

          <el-button
              v-if="isMenu === 2 && user.user_type == 4 && company_name"
              class="back_btn"
              size="medium"
              plain
              @click="Back(company_id)"
          >返回个人中心</el-button
          >
          <el-button
              v-if="isMenu === 1 && user.user_type == 3 && company_name"
              class="back_btn"
              size="medium"
              plain
              @click="Back(company_id)"
          >返回企业</el-button
          >
          <el-button
              v-else-if="isMenu === 1 && user.user_type == 4 && company_name"
              class="back_btn"
              size="medium"
              plain
              @click="Back(company_id)"
          >返回企业</el-button
          >
          <!--九龙县朗呷黑山猪猪仔猪场单独跳转-->
          <a href="http://www.hnyfwlw.com" v-if="company_id == '2581597'" style="margin-left: 10px;border: 1px solid #d3d7d4;padding: 7px;background-color: white;border-radius: 5px;font-size: 14px">智慧农业大数据平台</a>
        </el-header>
        <transition name="slide-fade">
          <router-view :companyData="companyData" :user="user"></router-view>
        </transition>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import axios from "../util/api";
import { local } from "../util/util";
import { mapState } from "vuex";
import md5 from "js-md5";
import bus from '@/util/bus'
export default {
  name: "admin",
  components: {},
  data() {
    return {
      defaultArr: [],
      user: "",
      isCollapse: false,
      menu_list: "",
      uploading: false,
      updialog: false,
      userloginname: "",
      total: null,
      companyData: [],
      page: {
        pager_offset: "0",
        pager_openset: "100",
      },
      navConfig: {},
      company_id: "",
      isMenu: "",
      activeNav: "",
      activeNav1: "/admin/public",
      company_name: "",
      fullScreen: false
    };
  },
  watch: {
    $route(to, from) {
      this.menu_list.forEach((item) => {
        item.menu_list.forEach((item1) => {
          if (to.path == item1.menu_url) {
            this.activeNav = item1.page_id;
            //console.log("log",this.activeNav);
          }
        });
      });

      // if (to.path == "/admin/home") {
      //   this.activeNav = "1-1";
      // } else if (to.path == "/admin/record") {
      //   this.activeNav = "2-1";
      // }
    },
    // $route (to, from) {

    //   var _this = this
    //   _this.menu_list.forEach(item => {
    //     item.menu_list.forEach(item1 => {
    //       if (to.path == item1.menu_url) {
    //         _this.activeNav = item1.page_id;
    //       }
    //     })
    //   })
    //   this.user = local.get('user');
    //   this.isMenu = local.get('isMenu');
    //   if (this.user.user_type == 3) {
    //     this.company_id = local.get('company_id');
    //     this.company_name = local.get('company_name');
    //   }
    // }
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      return path;
    },

  },
  mounted() {
    var _this = this;
    var href = window.location.href;
    href = href.split("#/admin/")[1];
    _this.activeNav = _this.navConfig[href];
    //console.log("activeNav",_this.activeNav);
    if(this.$route.query.fullScreen){
      this.fullScreen = this.$route.query.fullScreen == 1
    }
    this.topath = href;
    bus.$off('fullScreen')
    bus.$on('fullScreen',param =>{
      this.fullScreen = param.fullScreen
      let index = 0
      if(this.fullScreen){
        // let router = [
        //   {
        //   name: 'materialScreen',
        //   query: {
        //     fullScreen: 1
        //   }
        // }]
        let router = [
          {
          name: 'materialScreen',
          query: {
            fullScreen: 1
          }
        },{
          name: 'farmRecords',
          query: {
            fullScreen: 1
          }
        },{
          name: 'sensorScreen',
          query: {
            fullScreen: 1
          }
        },{
            name: 'controlScreen',
            query: {
              fullScreen: 1
            }
          },
        ]
        // setTimeout(() =>{
        //   this.$router.push(router[0])
        // },30000)
        //console.log('执行一次')
        let t = setInterval(() =>{
          this.$router.push(router[index])
          index = index == 3 ? 0 : index+1
        },30000)
        local.set('t',t)
      }else{
        bus.$off('fullScreen')
      }

    })
    bus.$off('full')
    bus.$on('full',param =>{
      this.fullScreen = param.full
    })
  },
  created() {
    this.userloginname = local.get("user_loginname");
    this.isMenu = local.get("isMenu");
    this.user = local.get("user");

    if (this.isMenu === 2) {
      this.menu_list = local.get("menu_list");
      if (this.menu_list == null) {
        this.getMenu();
      } else {
        this.menu_list.forEach((item) => {
          item.menu_list.forEach((item1) => {
            let menu = item1.menu_url.split("/admin/")[1];
            this.$set(this.navConfig, menu, item1.page_id);
          });
        });
      }

      if (this.user.user_type == 3 || this.user.user_type == 4) {
        this.getCompany(this.page);
      }
      // this.getMenu();
      //
    }
    const secounds = 1;
    let num = 0;
    const timer = setInterval(() => {
      if (num < secounds) {
        num++;
      } else {
        clearInterval(timer);
        if (this.user.user_type == 3 || this.user.user_type == 4) {
          this.company_id = local.get("company_id");
          this.company_name = local.get("company_name");
        }
      }
    }, 500);
  },

  methods: {
    getMenu() {
      var _this = this;
      axios.get("/pc/user-role/menu").then((v) => {
        _this.menu_list = v.data.menu_list;
        local.set("menu_list", _this.menu_list);
        _this.menu_list.forEach((item) => {
          item.menu_list.forEach((item1, index) => {
            let menu = item1.menu_url.split("/admin/")[1];
            this.$set(this.navConfig, menu, item1.page_id);
          });
        });
        var href = window.location.href;
        href = href.split("/admin/")[1];
        href = href.substring(0, href.lastIndexOf("?"));
        _this.activeNav = _this.navConfig[href];
      });
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    Collapse() {
      this.isCollapse = !this.isCollapse;
    },
    getCompany(params) {
      axios.get("/pc/company/list", params).then((v) => {
        this.companyData = v.data.company_list;
        this.total = v.data.pager_count;
      });
    },
    LoginOut(command) {
      let token = local.get("token");
      if (command === "logout") {
        axios.get("/pc/user/logOut", { token: token }).then((v) => {
          local.clear();
          this.$router.replace({
            path: "/login",
          });
        });
      } else if (command === "mine") {
        this.$router.push({ name: "Public" });
        local.set("isMenu", 1);
      }
    },
    changeCompany(command) {
      local.set("isMenu", 2);
      local.set("company_id", command.company_id);
      local.set("company_name", command.company_name);
      axios.get('/pc/user/single',{company_id: String(command.company_id)}).then((response) => {
        this.user = response.data.user;
        local.set('user', response.data.user);
      })
      this.goCompany(command.company_id);
    },
    handleCurrentChange(val) {
      this.page.pager_offset = (val - 1) * this.page.pager_openset + "";
      this.getCompany(this.page);
    },
    handleSizeChange(val) {
      this.page.pager_openset = val + "";
      this.getCompany(this.page);
    },
    Back(params) {
      if (this.isMenu == 1) {
        local.set("isMenu", 2);
        this.goCompany(this.company_id);
      } else {
        local.set("isMenu", 1);
        this.$router.push({ name: "Public" });
      }
    },
    goCompany(params) {
      axios
          .put("/pc/user-role/refresh/menu", { last_login_company_id: params })
          .then((v) => {
            this.menu_list = v.data.menu_list;
            local.set("menu_list", this.menu_list);
            this.url = v.data.menu_list[0].menu_list[0].menu_url;
            this.company_name = local.get("company_name");
            if (this.$route.path !== this.url){
              this.$router.replace({
                path: this.url,
              });
            }
          });
    },
  },
};
</script>
<style lang="scss" scoped>
//.bg-img{
//  background: url('~@/assets/image/bg1.jpg') no-repeat;
//  background-size: 100% 100%;
//}
.aside::-webkit-scrollbar{
  display: none;
}
.slide-fade {
  position: absolute;
  left: 0;
  right: 0;
}
.slide-fade-enter-active {
  transition: all 1.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(2, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  right: 0;
  transform: translateX(50px);
  opacity: 0;
}
.admin {
  a {
    color: unset;
  }
  .el-header {

    line-height: 60px;
    text-align: right;
    img {
      width: 30px;
      vertical-align: middle;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-main {
    padding: 0px;
  }
  .el-menu {
    border-right: none;
  }
  .logo {
    width: 70px;
    height: 70px;
    margin-top: 40px;
  }
  .el-menu-item [class^="el-icon-"] {
    vertical-align: text-bottom;
  }
  .el-menu-item {
    margin-left: -10px;
  }
  /deep/ .el-menu-item-group__title {
    padding: 0px !important;
  }

  .el-menu-item,
  .el-submenu__title {
    i {
      font-size: 21px;
      color: #fff;
    }
  }
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden!important;
  }
  /deep/ .el-submenu__title {
    padding-left: 22px !important;
  }
  .iconpeixun,
  .iconzuzhijigou {
    font-size: 18px !important;
  }
}
</style>
